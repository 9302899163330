import Cookies from 'js-cookie'

const cookies = Cookies.withConverter({
  read: (value, _name) => decodeURIComponent(value),
  write: (value, _name) => encodeURIComponent(value),
})

const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

if (timeZone !== undefined) {
  cookies.set(
    'browser_time_zone',
    timeZone,
    {
      expires: 365, // days
      path: '/',
    }
  )
}
