$(() => {
  $('.grid-btn').on('click', () => {
    $('.section-grid-list').removeClass('section-grid-list-vertical')
  })

  $('.list-btn').on('click', () => {
    $('.section-grid-list').removeClass('section-grid-list-vertical')
    $('.section-grid-list').addClass('section-grid-list-vertical')
  })

  $('.tab-link').on('click', function () {
    const tab = $(this).data('tab')

    $('.tab-content').hide()
    $('#' + tab).show()
    $('.tab-link').removeClass('btn-primary')
    $('.tab-link').addClass('btn-outline-primary')
    $(this).removeClass('btn-outline-primary')
    $(this).addClass('btn-primary')
  })

  if (location.hash !== '') {
    $(`.tab-link[data-tab="${location.hash.slice(1)}"]`).trigger('click')
  }

  $('#hamburger').on('click', (e) => {
    e.preventDefault()

    const { style } = document.getElementById('myLinks')
    style.display = style.display === 'block' ? 'none' : 'block'
  })
})
