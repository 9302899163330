/**
 * Converts the characters "&", "<", ">", '"', and "'" in `string` to their corresponding HTML entities.
 *
 * @param {string} string The string to escape.
 * @returns {string} Returns the escaped string.
 */
export const escape = (string) =>
  string
    .replace(/'/g, '&#039;')
    .replace(/"/g, '&quot;')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')

export const setupMutuallyExclusiveCheckboxesGroup = () => {
  $(document).on('change', '[data-on-checked-uncheck-target]', (event) => {
    if (!event.currentTarget.checked) return

    $(event.currentTarget.dataset.onCheckedUncheckTarget).prop('checked', false)
  })
}
