import $ from 'jquery'
import { throttle } from 'lodash-es'

const targetEvents = [
  [document, 'click'],
  [document, 'keydown'],
  [document, 'keyup'],
  [document, 'mousedown'],
  [document, 'mousemove'],
  [document, 'mouseup'],
  [document, 'scroll'],
  [document, 'touchend'],
  [document, 'touchstart'],
  [window, 'blur'],
  [window, 'focus'],
]

export const start = ({ url, wait = 5000 }) => {
  const sendHeartbeat = throttle(() => {
    $.ajax(url, { method: 'POST' })
  }, wait)

  const stop = () => {
    targetEvents.forEach(([target, eventName]) => {
      target.removeEventListener(eventName, sendHeartbeat)
    })
  }

  targetEvents.forEach(([target, eventName]) => {
    target.addEventListener(eventName, sendHeartbeat)
  })

  return stop
}
